import axios from 'axios';
import { removeAuthInfoFromLocalStorage } from '../utils/helper';

// const local = 'https://tbn-driver-prod.uc.r.appspot.com/api';
// const local = 'https://tbn-driver-dev.uc.r.appspot.com/api';
const local = 'https://tbn-driver-qa.uc.r.appspot.com/api';
// const local = 'https://tbn-driver-stage.uc.r.appspot.com/api';
// const local = 'http://localhost:8080/api';

export const baseURL = local;
const baseService = axios.create({ baseURL });
export const Service = axios.create({ baseURL });
const quickBaseUrl = 'https://thebusnetwork.quickbase.com/db';
export const quickBaseService = axios.create({ baseURL: quickBaseUrl });

baseService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : '';
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            //  config.params =  {companyId: getCompanyId(), ...config.params};
            config.params = { ...config.params };
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

baseService.interceptors.response.use(
    (config) => {
        let currentVersion = localStorage.getItem('driverportal_version');
        if (currentVersion === config.headers.driverportal_version) {
            return config;
        } else {
            removeAuthInfoFromLocalStorage();
            window.location.href = '/login';
        }
    },
    (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            return Promise.reject({
                response: {
                    data: { error: error.response.data.message || 'please login first' },
                },
            });
        }
        return Promise.reject(error);
    }
);

export default baseService;
